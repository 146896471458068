import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React from 'react'
import Layout from "../components/layout"
import Forgot from '../containers/resend'
import { Container } from 'reactstrap'

const forgot = () => {
  return (
    <Layout>

      <Container
        style={{
          padding: '2rem 2rem 1rem',
          minHeight: '75vh'
        }}
      >
        <Forgot />
      </Container>
    </Layout>

  )
}

export default forgot
