import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import {Row, Col, Form, FormGroup, FormFeedback, Input, Button} from 'reactstrap'
import { handleResend } from '../services/auth'
import validator from 'validator'

import formStyles from '../components/styles/form.module.css'
import { navigate } from 'gatsby';
import { CODE_RESEND } from '../components/authcode';


export default class resend extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            message: "",
            formValid : false,
            respError: false,
            formError : false,
            btnMessage : "Submit",
            defaultState : true,
            isLoading : false,
        };
    }

    validateField(value) {
        let emailValid = this.state.formError
        let message = this.state.message

        if(validator.isEmail(value)){
            emailValid = true
            message = ""
        } else {
            emailValid = false
            message = "Invalid Email address"
        }

        this.setState({
            emailValid: emailValid,
            message: message,
            defaultState : false

        }, this.validateForm(emailValid))

    }

    validateForm(validation){
        this.setState({
            formValid : validation
        })

    }

    handleChange = event => {
        const name = event.target.id;
        const value = event.target.value;
        this.setState({
            [name]: value
        },
            () => {
                this.validateField(value)
            }
        );
    }


    handleSubmit = async event => {
        event.preventDefault()
        this.setState({
            btnMessage : "Loading...",
            isLoading : true
        })
        await handleResend(this.state)
            .then(async (data) => {
                if(data.isValid){
                    if(data.code === CODE_RESEND.USER_NOT_VERIFIED){

                        navigate('/resent/', {state : { data : data.type}})

                    } else {

                        navigate('/resent/', {state : { data : data.type}})

                    }
                } else {

                    navigate('/resent/')

                    // switch (data.code) {
                    //     case CODE_RESEND.USER_NOT_FOUND:
                    //         this.setState({
                    //             emailValid : false,
                    //             message : "not found",
                    //             btnMessage : "Submit",
                    //             isLoading : false
                    //         })

                    //         break;

                    //     case CODE_RESEND.LAMBDA_ERROR:
                    //         this.setState({
                    //             emailValid : false,
                    //             message : "An unknown has been encountered. (E0x10002)",
                    //             btnMessage : "Submit",
                    //             isLoading : false
                    //     })
                    //     break;

                    //     case CODE_RESEND.USER_LIMIT_EXCEED:
                    //         this.setState({
                    //             emailValid : false,
                    //             message : "You have exceeded the number of allowed password resetting. Please try again after a while.",
                    //             btnMessage : "Submit",
                    //             isLoading : false
                    //     })
                    //     break;

                    //     default:
                    //         this.setState({
                    //             emailValid : false,
                    //             message : "Something went wrong. (E0x10001)",
                    //             btnMessage : "Submit",
                    //             isLoading : false
                    //         })
                    //         break;
                    // }
                }
            })
            .catch((err) => {
                console.log(err)
                this.setState({
                    emailValid : false,
                    message : "Something went wrong. (E0x10002)",
                    btnMessage : "Submit",
                    isLoading : false
                })
            });

    }

  render() {
    return (
      <Row className={formStyles.formContainer}>
            <Col>
                <h3 style={{
                    fontWeight: 'bolder'
                }}>
                    Having trouble signing in?
                </h3>
                <p>
                    Please enter the email address for your account. A verification code will be sent to you. Once you have received the verification code, you will be able to choose a new password for your account. If your account has not been activated, an activation link will be sent instead.
                </p>
                <Form
                    onSubmit = { this.handleSubmit}
                >
                    <FormGroup>
                        <Input
                            placeholder="Email Address"
                            required
                            type="email"
                            id="email"
                            onChange={this.handleChange}
                            valid={!this.state.formError && this.state.formValid}
                            invalid={!this.state.emailValid && !this.state.defaultState ? true : undefined}
                        />
                    </FormGroup>
                    <Button
                        disabled={!this.state.formValid || this.state.isLoading}
                        color={!this.state.formValid || this.state.isLoading ? 'secondary' : 'primary'}
                    >
                        {this.state.btnMessage}
                    </Button>
                </Form>
            </Col>
      </Row>
    )
  }
}
